import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { CalendarSchedule } from "../../helper/types";
import * as css from "./ScheduleCell.scss";

const getCssClass = (schedule: CalendarSchedule): string => {
  const scheduleStatus = schedule.displayedScheduleStatus;

  if (
    [
      DisplayedScheduleStatus.CreatedViaApi,
      DisplayedScheduleStatus.WorkInProgress,
    ].includes(scheduleStatus)
  ) {
    return css.draftCell;
  }

  if (DisplayedScheduleStatus.InReview === scheduleStatus) {
    return css.inReviewCell;
  }

  if (
    [
      DisplayedScheduleStatus.Approved,
      DisplayedScheduleStatus.InGeneration,
      DisplayedScheduleStatus.Published,
      DisplayedScheduleStatus.Accepted,
      DisplayedScheduleStatus.PricesPublished,
    ].includes(scheduleStatus)
  ) {
    return css.processingCell;
  }

  if (DisplayedScheduleStatus.Hidden === scheduleStatus) {
    if (hasUserPermission(Feature.VIEW_HIDDEN_STATUS)) {
      return css.hiddenCell;
    }

    return css.processingCell;
  }

  if (
    [DisplayedScheduleStatus.OnSale, DisplayedScheduleStatus.PartiallyOnSale].includes(
      scheduleStatus
    )
  ) {
    return css.onSaleCell;
  }

  if (
    [DisplayedScheduleStatus.Archived, DisplayedScheduleStatus.OutOfSale].includes(
      scheduleStatus
    )
  ) {
    return css.inactiveCell;
  }

  return "";
};

export default getCssClass;
