import {
  Feature,
  hasUserPermission,
  localizeDate,
} from "@flixbus-phx/marketplace-common";
import { isSameDay } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Chip from "../../../../shared/components/chip/Chip";
import { PricingPeriod, Schedule } from "../../../../shared/types/schema";
import AddPeriodButton from "../addPeriodButton/AddPeriodButton";
import * as css from "./PeriodChips.scss";

export type PeriodChipsProps = {
  periods: NonNullable<PricingPeriod["period"]>[];
  selectedPeriod: NonNullable<PricingPeriod["period"]>["start"] | null;
  onPeriodSelect: (period: NonNullable<PricingPeriod["period"]>["start"] | null) => void;
  onPeriodDelete: (
    periodToDeleteStartDate: NonNullable<PricingPeriod["period"]>["start"]
  ) => void;
  onPeriodAdd: (newPeriod: NonNullable<PricingPeriod["period"]>) => void;
  schedulePeriod: Schedule["period"];
  readOnly: boolean;
};

const PeriodChips: React.FC<PeriodChipsProps> = ({
  periods,
  selectedPeriod,
  onPeriodSelect,
  onPeriodDelete,
  onPeriodAdd,
  schedulePeriod,
  readOnly,
}) => {
  const { formatMessage } = useIntl();

  const periodToString = (period: PricingPeriod["period"] | undefined): string => {
    if (!period) {
      return "";
    }

    const start = new Date(period.start);
    const end = new Date(period.end);

    return isSameDay(start, end)
      ? localizeDate(start)
      : `${localizeDate(start)} - ${localizeDate(end)}`;
  };

  return (
    <div className={css.wrapper}>
      <Chip isHighlighted={selectedPeriod === null} onClick={() => onPeriodSelect(null)}>
        <FormattedMessage id="pricing.period.default" />
      </Chip>
      {[...periods]
        .sort((a, b) => {
          return new Date(a.start).getTime() - new Date(b.start).getTime();
        })
        .map((period) => {
          const isHighlighted = selectedPeriod === period.start;
          const periodString = periodToString(period);

          return (
            <Chip
              key={period.start}
              isHighlighted={isHighlighted}
              onClick={() => onPeriodSelect(period.start)}
              onDelete={
                hasUserPermission(Feature.EDIT_PRICING) && !readOnly
                  ? () => {
                      onPeriodDelete(period.start);
                    }
                  : undefined
              }
              deleteConfirmationPopupTitle={formatMessage({
                id: "pricing.period.delete.title",
              })}
              deleteConfirmationPopupContent={formatMessage(
                { id: "pricing.period.delete.content" },
                { period: `"${periodString}"` }
              )}
            >
              {periodString}
            </Chip>
          );
        })}
      {hasUserPermission(Feature.EDIT_PRICING) && !readOnly && (
        <>
          <AddPeriodButton
            existingPeriods={periods}
            schedulePeriod={schedulePeriod}
            onPeriodAdd={onPeriodAdd}
          />
        </>
      )}
    </div>
  );
};

export default PeriodChips;
