import { compareAsc, endOfMonth, interval, startOfMonth } from "date-fns";
import isScheduleOnSale from "../isScheduleOnSale/isScheduleOnSale";
import {
  Calendar,
  CalendarIntervalType,
  CalendarSchedule,
  InputSchedule,
  ScheduleChunk,
} from "../types";
import growInterval from "./buildCalendar";

const buildDailyCalendar = (inputSchedules: Array<InputSchedule>): Calendar => {
  const now = new Date();
  let boundary = interval(now, now);
  const rows = inputSchedules
    .map((inputSchedule) => {
      const chunks: Array<ScheduleChunk> = [];
      const processedSchedules = new Set<CalendarSchedule>();

      if (isScheduleOnSale(inputSchedule)) {
        [inputSchedule, ...inputSchedule.conceptChanges].forEach((schedule) => {
          schedule.onSalePeriods.forEach((period) => {
            const isFirstOccurrence = !processedSchedules.has(schedule);
            if (isFirstOccurrence) processedSchedules.add(schedule);

            const normalizedInterval = interval(period.start, period.end);

            boundary = growInterval(boundary, normalizedInterval);

            chunks.push({
              uiPeriod: normalizedInterval,
              isFirstOccurrence,
              schedules: [schedule],
            });
          });
        });
      } else {
        const isFirstOccurrence = !processedSchedules.has(inputSchedule);
        if (isFirstOccurrence) processedSchedules.add(inputSchedule);

        const normalizedInterval = interval(
          inputSchedule.period!!.start,
          inputSchedule.period!!.end
        );

        boundary = growInterval(boundary, normalizedInterval);

        chunks.push({
          uiPeriod: normalizedInterval,
          isFirstOccurrence,
          schedules: [inputSchedule],
        });
      }

      chunks.sort((a, b) => compareAsc(a.uiPeriod.start, b.uiPeriod.start));

      return {
        columns: chunks,
      };
    })
    .filter((row) => row.columns.length > 0);

  return {
    intervalType: CalendarIntervalType.DAILY,
    boundary: interval(startOfMonth(boundary.start), endOfMonth(boundary.end)),
    rows,
  };
};

export default buildDailyCalendar;
