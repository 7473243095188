import {
  compareAsc,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  interval,
  isWithinInterval,
  NormalizedInterval,
  startOfMonth,
  startOfYear,
} from "date-fns";
import isScheduleOnSale from "../isScheduleOnSale/isScheduleOnSale";
import {
  Calendar,
  CalendarIntervalType,
  CalendarRow,
  CalendarSchedule,
  InputSchedule,
  ScheduleChunk,
} from "../types";
import growInterval from "./buildCalendar";

export const addScheduleToMonthlyBucketsAndGrowInterval = (
  schedule: CalendarSchedule,
  buckets: Map<number, Set<CalendarSchedule>>,
  intervalToGrow: NormalizedInterval
): NormalizedInterval => {
  let grownInterval = intervalToGrow;

  if (isScheduleOnSale(schedule)) {
    schedule.onSalePeriods.forEach((onSalePeriod) => {
      grownInterval = growInterval(
        grownInterval,
        interval(onSalePeriod.start, onSalePeriod.end)
      );

      eachMonthOfInterval<Date>(onSalePeriod).forEach((month) => {
        const monthTime = month.getTime();
        const bucket = buckets.get(monthTime) || new Set<CalendarSchedule>();
        bucket.add(schedule);
        buckets.set(monthTime, bucket);
      });
    });
  } else {
    grownInterval = growInterval(
      grownInterval,
      interval(schedule.period?.start, schedule.period?.end)
    );

    eachMonthOfInterval<Date>(schedule.period!!).forEach((month) => {
      const monthTime = month.getTime();
      const bucket = buckets.get(monthTime) || new Set<CalendarSchedule>();
      bucket.add(schedule);
      buckets.set(monthTime, bucket);
    });
  }

  return grownInterval;
};

const newMonthlyScheduleChunk = (
  period: NormalizedInterval<Date>,
  schedules: Array<CalendarSchedule> = []
): ScheduleChunk => ({
  uiPeriod: period,
  isFirstOccurrence: false,
  schedules,
});

export const getEarliestIntervalInMonth = (
  intervals: Array<NormalizedInterval>,
  month: Date
): NormalizedInterval => {
  const matchingIntervals = intervals.filter((i) => {
    return isWithinInterval(month, interval(startOfMonth(i.start), endOfMonth(i.end)));
  });

  if (matchingIntervals.length === 0) {
    throw new Error("No matching interval found");
  }

  matchingIntervals.sort((a, b) => compareAsc(a.start, b.start));

  return matchingIntervals[0];
};

export const sortSchedulesByOnSalePeriodInMonthAsc = <S extends CalendarSchedule>(
  month: Date,
  schedules: Array<S>
): Array<S> => {
  const sortedSchedules = schedules.slice();

  sortedSchedules.sort((a, b) => {
    const periodA = getEarliestIntervalInMonth(a.onSalePeriods, month);
    const periodB = getEarliestIntervalInMonth(b.onSalePeriods, month);

    return compareAsc(periodA.start, periodB.start);
  });

  return sortedSchedules;
};

const buildMonthlyCalendar = (inputSchedules: Array<InputSchedule>): Calendar => {
  const now = new Date();
  let boundary = interval(startOfMonth(now), endOfMonth(now));
  const rows = inputSchedules
    .map((inputSchedule) => {
      // Put all schedules in monthly buckets and calculate the complete interval
      const monthlyBuckets = new Map<number, Set<CalendarSchedule>>();
      let completeInterval = interval(startOfMonth(now), endOfMonth(now));

      completeInterval = addScheduleToMonthlyBucketsAndGrowInterval(
        inputSchedule,
        monthlyBuckets,
        completeInterval
      );
      inputSchedule.conceptChanges.forEach((conceptChange) => {
        if (isScheduleOnSale(conceptChange)) {
          completeInterval = addScheduleToMonthlyBucketsAndGrowInterval(
            conceptChange,
            monthlyBuckets,
            completeInterval
          );
        }
      });

      boundary = growInterval(boundary, completeInterval);

      // Iterate over monthly buckets and consolidate the schedules into chunks
      const processedSchedules = new Set<CalendarSchedule>();
      const calendarRow: CalendarRow = {
        columns: [],
      };
      let currentScheduleChunk = newMonthlyScheduleChunk(completeInterval);

      eachMonthOfInterval(completeInterval).forEach((currentMonth) => {
        const schedulesInMonth = monthlyBuckets.get(currentMonth.getTime());
        if (!schedulesInMonth) {
          return;
        }

        // same schedules as in the chunk before, just extend the period
        if (
          currentScheduleChunk.schedules.length === schedulesInMonth.size &&
          currentScheduleChunk.schedules.every((schedule) =>
            schedulesInMonth.has(schedule)
          )
        ) {
          currentScheduleChunk.uiPeriod.end = endOfMonth(currentMonth);
          return;
        }

        // different schedules, put the current chunk in the row ...
        if (currentScheduleChunk.schedules.length > 0) {
          calendarRow.columns.push(currentScheduleChunk);
        }

        // ... and start a new chunk
        currentScheduleChunk = newMonthlyScheduleChunk(
          interval(startOfMonth(currentMonth), endOfMonth(currentMonth)),
          sortSchedulesByOnSalePeriodInMonthAsc(
            currentMonth,
            Array.from(schedulesInMonth)
          )
        );

        schedulesInMonth.forEach((schedule) => {
          if (!processedSchedules.has(schedule)) {
            currentScheduleChunk.isFirstOccurrence = true;
            processedSchedules.add(schedule);
          }
        });
      });

      // after the loop, push the remaining chunk to the row
      if (currentScheduleChunk.schedules.length > 0) {
        calendarRow.columns.push(currentScheduleChunk);
      }

      return calendarRow;
    })
    .filter((row) => row.columns.length > 0);

  return {
    intervalType: CalendarIntervalType.MONTHLY,
    boundary: interval(startOfYear(boundary.start), endOfYear(boundary.end)),
    rows,
  };
};

export default buildMonthlyCalendar;
