import {
  Icon,
  IconCheckmark,
  IconClose,
  IconEditSolid,
  IconLockSolid,
} from "@flixbus/honeycomb-icons-react";
import { Button, FormRow } from "@flixbus/honeycomb-react";
import {
  API_DATE_FORMAT,
  localizeDate,
  parseDate,
} from "@flixbus-phx/marketplace-common";
import classNames from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Maybe, SchedulePeriod } from "../../types/schema";
import Datepicker from "../datepicker/Datepicker";
import * as css from "./EditableDatepicker.scss";

type Props = {
  value?: Maybe<SchedulePeriod>;
  onValueChange: (value?: SchedulePeriod) => void;
  isLoading?: boolean;
  readOnly?: boolean;
};

const EditableDatepicker: React.FC<Props> = ({
  value,
  onValueChange,
  isLoading = false,
  readOnly = false,
}) => {
  const { formatMessage } = useIntl();
  const [editable, setEditable] = React.useState(false);
  const open = () => setEditable(true);
  const close = () => setEditable(false);
  const ref = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    const elem = document.getElementById("picker-start");
    if (elem !== null) elem.focus();
  }, [editable]);

  const handleClickOutside: EventListener = (event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      close();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  let datePickerValues: { start?: Date; end?: Date } = {
    start: value ? parseDate(value.start) : undefined,
    end: value ? parseDate(value.end) : undefined,
  };

  const handleSubmit = () => {
    let formattedPeriod;
    if (datePickerValues.start && datePickerValues.end) {
      formattedPeriod = {
        start: format(datePickerValues.start, API_DATE_FORMAT),
        end: format(datePickerValues.end, API_DATE_FORMAT),
      };
    }
    onValueChange(formattedPeriod);
    close();
  };

  if (editable && !readOnly) {
    return (
      <form noValidate onSubmit={handleSubmit} onReset={close} ref={ref}>
        <div className={css.flexBox}>
          <div className={css.inputFlex}>
            <FormRow>
              <Datepicker
                leftLabel={formatMessage({ id: "calendar.period.input.start" })}
                leftId="picker-start"
                rightLabel={formatMessage({ id: "calendar.period.input.end" })}
                rightId="picker-end"
                initialValues={datePickerValues}
                onValuesSet={(newValues: { start?: Date; end?: Date }) => {
                  datePickerValues = newValues;
                }}
                isEditable
              />
            </FormRow>
          </div>
          <div className={css.buttonPosition}>
            <Button
              type="reset"
              appearance="danger"
              display="square"
              extraClasses={css.cancelButton}
              aria-label="reset"
            >
              <Icon InlineIcon={IconClose} />
            </Button>
          </div>
          <div className={css.buttonPosition}>
            <Button
              type="submit"
              appearance="tertiary"
              display="square"
              onMouseDown={handleSubmit}
              extraClasses={css.submitButton}
              aria-label="submit"
            >
              <Icon InlineIcon={IconCheckmark} />
            </Button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div
      data-id="on-click-editable"
      onClick={open}
      className={classNames(
        css.notEditableWrapper,
        isLoading ? css.notEditableWrapperLoading : ""
      )}
    >
      <span
        className={readOnly ? css.readOnlyText : css.notEditableText}
        data-id="editable-text"
      >
        {value ? (
          `${localizeDate(value.start)} - ${localizeDate(value.end)}`
        ) : (
          <i>
            <FormattedMessage id="general.input.placeholder" />
          </i>
        )}
      </span>
      <span
        className={readOnly ? css.readOnlyEditIcon : css.notEditableEditIcon}
        data-id="edit-icon"
      >
        <Icon InlineIcon={readOnly ? IconLockSolid : IconEditSolid} />
      </span>
    </div>
  );
};

export default EditableDatepicker;
