import { Infobox } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Currency,
  PriceDirection,
  Schedule,
  ScheduleStatus,
} from "../../shared/types/schema";
import {
  pricesInEditModeVar,
  priceInvalidForScheduleVar,
} from "../../state/reactiveVariables/reactiveVariables";
import usePriceInvalidForSchedule from "../../state/usePriceInvalidForSchedule/usePriceInvalidForSchedule";
import usePricesInEditMode from "../../state/usePricesInEditMode/usePricesInEditMode";
import * as css from "./PriceMatrix.scss";
import {
  useFindPricingQuery,
  useUpdatePricingPeriodsMutation,
} from "./api/operations.generated";
import PriceActionBar from "./containers/priceActionBar/PriceActionBar";
import PriceTable from "./containers/priceTable/PriceTable";
import {
  clearAllRelationMatrices,
  mirrorRelationMatrix,
  convertPricingPeriodsToPricingPeriodsInput,
  updateRelationMatrixEntry,
  updateMirroredRelationMatrix,
  changePriceDirectionOfPricingPeriod,
  getPriceMatrixIndexBasedOnPeriod,
  filterPeriod,
  addPeriod,
} from "./helpers/updateRelationMatrix/updateRelationMatrix";
import { PricingPeriodsFromQuery } from "./types";
import PeriodChips from "./ui/periodChips/PeriodChips";
import PriceInfobox from "./ui/priceInfobox/PriceInfobox";
import PriceMatrixSpinner from "./ui/priceMatrixSpinner/PriceMatrixSpinner";
import ToggleStateButton from "./ui/toggleStateButton/ToggleStateButton";

type Props = {
  scheduleId: Schedule["id"];
};

const PriceMatrix: React.FC<Props> = ({ scheduleId }) => {
  const [currency, setCurrency] = React.useState<Currency>();
  const [pricingPeriods, setPricingPeriods] = React.useState<PricingPeriodsFromQuery>([]);

  const [priceInvalidForSchedule, setPriceInvalidForSchedule] =
    usePriceInvalidForSchedule(priceInvalidForScheduleVar);

  const [pricesInEditMode, setPricesInEditMode] =
    usePricesInEditMode(pricesInEditModeVar);

  const [selectedPeriod, setSelectedPeriod] = React.useState<string | null>(null);

  const selectedPeriodsIndex = pricingPeriods.length
    ? getPriceMatrixIndexBasedOnPeriod(pricingPeriods, selectedPeriod)
    : 0;

  React.useEffect(() => {
    if (priceInvalidForSchedule === scheduleId) {
      setPricesInEditMode(true);
    }

    return () => setPricesInEditMode(false);
  }, [priceInvalidForSchedule]);

  const { data, loading: queryLoading } = useFindPricingQuery({
    variables: {
      ID: scheduleId,
    },
    onCompleted: (pricingData) => {
      setCurrency(pricingData.findSchedule.currency);
      setPricingPeriods(pricingData.findSchedule.pricingPeriods);
    },
  });

  const [updatePrices, { loading: mutationLoading }] = useUpdatePricingPeriodsMutation({
    onCompleted: () => {
      setPricesInEditMode(false);
    },
  });

  const handlePriceChange = (row: number, col: number, value: number | null): void => {
    // We need to set the state with the previous matrix in order to break the memoization caused by useCallback in PriceTable
    if (
      pricingPeriods[selectedPeriodsIndex].direction === PriceDirection.UniDirectional
    ) {
      setPricingPeriods((prevPricingPeriods) =>
        updateRelationMatrixEntry(
          row,
          col,
          value,
          prevPricingPeriods,
          selectedPeriodsIndex
        )
      );
      return;
    }

    setPricingPeriods((prevPricingPeriods) =>
      updateMirroredRelationMatrix(
        row,
        col,
        value,
        prevPricingPeriods,
        selectedPeriodsIndex
      )
    );
  };

  const resetPriceInvalidForSchedule = () => {
    if (priceInvalidForSchedule === scheduleId) {
      setPriceInvalidForSchedule("");
    }
  };

  if (queryLoading) {
    return <PriceMatrixSpinner />;
  }

  if (data?.findSchedule) {
    const schedule = data.findSchedule;
    const isPriceEditDisabled = [
      ScheduleStatus.Approved,
      ScheduleStatus.InGeneration,
      ScheduleStatus.Published,
      ScheduleStatus.Accepted,
    ].includes(schedule.status);

    if (!schedule.stations.length) {
      return <PriceInfobox isError={false} />;
    }

    if (currency && pricingPeriods.length) {
      return (
        <>
          {schedule.wasCreatedViaApi ? (
            <div className={css.infoboxWrapper}>
              <Infobox data-id="api-infobox" small extraClasses={css.infobox}>
                <FormattedMessage id="prices.info.createdViaApi" />
              </Infobox>
            </div>
          ) : (
            <>
              <div className={css.wrapper}>
                <div className={css.editButtonWrapper}>
                  <PeriodChips
                    periods={pricingPeriods
                      .filter((period) => period.period !== null)
                      .map((period) => {
                        return period.period!;
                      })}
                    selectedPeriod={selectedPeriod}
                    onPeriodSelect={(periodStartDate) => {
                      setSelectedPeriod(periodStartDate);
                    }}
                    onPeriodDelete={(periodStartDate) => {
                      setPricingPeriods((prevPricingPeriods) => {
                        return filterPeriod(prevPricingPeriods, periodStartDate);
                      });
                      setSelectedPeriod(null);
                    }}
                    onPeriodAdd={(newPeriod) => {
                      setPricingPeriods((prevPricingPeriods) => {
                        return addPeriod(prevPricingPeriods, newPeriod);
                      });
                    }}
                    schedulePeriod={schedule.period}
                    readOnly={!pricesInEditMode}
                  />
                  {hasUserPermission(Feature.EDIT_PRICING) && (
                    <ToggleStateButton
                      isInEditMode={pricesInEditMode}
                      onToggle={() => {
                        setPricesInEditMode(true);
                      }}
                      disabled={isPriceEditDisabled}
                      onSubmit={() => {
                        updatePrices({
                          variables: {
                            scheduleId,
                            pricingPeriodInputs:
                              convertPricingPeriodsToPricingPeriodsInput(pricingPeriods),
                            currency,
                          },
                        });
                        resetPriceInvalidForSchedule();
                      }}
                      onCancel={() => {
                        setPricesInEditMode(false);
                        setPricingPeriods(schedule.pricingPeriods);
                        setCurrency(schedule.currency);
                        resetPriceInvalidForSchedule();
                      }}
                      isSubmitLoading={mutationLoading}
                    />
                  )}
                </div>

                {pricesInEditMode && hasUserPermission(Feature.EDIT_PRICING) && (
                  <PriceActionBar
                    schedule={schedule}
                    currency={currency}
                    priceDirection={pricingPeriods[selectedPeriodsIndex].direction}
                    onCurrencyChange={(newCurrency, resetPrices) => {
                      if (resetPrices) {
                        setPricingPeriods(clearAllRelationMatrices(pricingPeriods));
                      }
                      setCurrency(newCurrency);
                    }}
                    onPriceDirectionChange={(newPriceDirection) => {
                      if (newPriceDirection === PriceDirection.BiDirectional) {
                        setPricingPeriods((prevPricingPeriods) =>
                          mirrorRelationMatrix(prevPricingPeriods, selectedPeriodsIndex)
                        );
                      }
                      setPricingPeriods((prevPricingPeriods) =>
                        changePriceDirectionOfPricingPeriod(
                          prevPricingPeriods,
                          selectedPeriodsIndex,
                          newPriceDirection
                        )
                      );
                    }}
                    onRelationMatrixImport={(importedRelationMatrix) => {
                      setPricingPeriods((prevPricingPeriods) => {
                        const newPricingPeriods = [...prevPricingPeriods];
                        newPricingPeriods[selectedPeriodsIndex].relationMatrix =
                          importedRelationMatrix;
                        return newPricingPeriods;
                      });
                    }}
                  />
                )}
              </div>
            </>
          )}
          <PriceTable
            // We set this key in order to render a new Table when the selectedPeriod changes
            key={selectedPeriod}
            scheduleId={scheduleId}
            lineId={schedule.line.id}
            stations={schedule.stations}
            scheduleDirection={schedule.scheduleDirection}
            currency={currency}
            priceDirection={pricingPeriods[selectedPeriodsIndex].direction}
            relationMatrix={pricingPeriods[selectedPeriodsIndex].relationMatrix}
            onPriceChange={(row, col, value) => handlePriceChange(row, col, value)}
            readOnly={schedule.wasCreatedViaApi || !pricesInEditMode}
          />
        </>
      );
    }

    return <PriceMatrixSpinner />;
  }

  return <PriceInfobox isError />;
};

export default PriceMatrix;
