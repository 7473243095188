import { Icon, IconAttention, IconClose } from "@flixbus/honeycomb-icons-react";
import cx from "classnames";
import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../confirmationPopup/ConfirmationPopup";
import * as css from "./Chip.scss";

export type ChipProps = {
  isHighlighted: boolean;
  onClick: () => void;
  onDelete?: () => void;
  deleteConfirmationPopupTitle?: string;
  deleteConfirmationPopupContent?: string;
};

const Chip: React.FC<ChipProps> = ({
  isHighlighted,
  onClick,
  onDelete,
  deleteConfirmationPopupTitle,
  deleteConfirmationPopupContent,
  children,
}) => {
  const { formatMessage } = useIntl();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div className={cx(isHighlighted && css.highlighted, css.chip)} onClick={onClick}>
      {children}
      {onDelete && (
        <Icon
          InlineIcon={IconClose}
          size={3}
          onClick={(e) => {
            e.stopPropagation();
            setShowPopup(true);
          }}
          extraClasses={css.icon}
        />
      )}
      {onDelete && deleteConfirmationPopupTitle && (
        <ConfirmationPopup
          active={showPopup}
          titleText={deleteConfirmationPopupTitle}
          titleIcon={IconAttention}
          confirmButtonText={formatMessage({ id: "general.delete" })}
          onConfirm={() => {
            onDelete!!();
            setShowPopup(false);
          }}
          onCancel={() => setShowPopup(false)}
        >
          {deleteConfirmationPopupContent}
        </ConfirmationPopup>
      )}
    </div>
  );
};

export default Chip;
