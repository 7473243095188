@import "variables";

.button {
  color: $success-dark-color;
  font-weight: 500;
}

.text {
  font-size: 14px;
}

.inputWrapper {
  width: 296px;
  margin: $spacing-2 auto;
  text-align: left;
}
