import { NormalizedInterval } from "date-fns";
import { OriginalSchedulesOfLineQuery } from "../api/operations.generated";

export enum CalendarIntervalType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
}

export type InputSchedule = OriginalSchedulesOfLineQuery["findOriginalSchedules"][number];

export type CalendarSchedule = Omit<InputSchedule, "conceptChanges">;

export type ScheduleChunk = {
  uiPeriod: {
    start: Date;
    end: Date;
  };
  isFirstOccurrence: boolean;
  schedules: Array<CalendarSchedule>;
};

export type CalendarRow = {
  columns: Array<ScheduleChunk>;
};

export type Calendar = {
  intervalType: CalendarIntervalType;
  boundary: NormalizedInterval;
  rows: Array<CalendarRow>;
};
